.component {
  overflow: hidden;
  height: calc(var(--height-in-vh, 400) * 1vh); /* var is set in js, because gsap needs it too */

  & > .layout {
    width: 100%;
    height: 100vh;
  }
}

.layout {
  display: grid;
  grid-template-areas: 'payoff-stack';
  align-items: start;

  & > * {
    grid-area: payoff-stack;
    min-width: 0;
  }
}

.payoff {
  display: grid;
  align-items: center;
  position: relative;
  z-index: 0;

  & > * {
    min-width: 0;
    grid-area: 1 / -1;
  }

  & > .heading {
    margin-inline: auto;
    max-width: 30ch;
  }

  & > .imageContainer {
    z-index: -1;
  }

  &:not(.payoff0) {
    opacity: 0;
  }
}

.imageContainer {
  overflow: hidden;

  & > .image {
    width: 100%;
    height: 100vh;
  }
}

.image {
  object-fit: cover;
  object-position: top center;
}

.heading {
  text-align: center;
  color: var(--color-beige);
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  padding-inline: var(--container-padding);
}
