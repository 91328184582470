.component {
  --z-index-yearCounter: 0;
  --z-index-steam-and-pan: 1;

  position: relative;
  z-index: 0;
  display: grid;
  grid-template-areas:
    'intro'
    'pan-and-counter'
    'outro';
  justify-items: center;
  align-items: start;
  text-align: center;
  color: var(--color-beige);

  & > .intro {
    grid-area: intro;
    margin-top: 30vh;

    @media (--viewport-sm) {
      margin-top: 50vh;
    }
  }

  & > .bgImage {
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
  }

  & > .yearCounterComponent {
    grid-area: pan-and-counter;
    position: relative;
    margin-top: 15vh;
    margin-left: -0.15em;/* correction to make it look centered for thousands number that is right-aligned */
    z-index: var(--z-index-yearCounter);
  }

  & > .componentPanAndSteam {
    grid-area: pan-and-counter;
    position: relative;
    z-index: var(--z-index-steam-and-pan);
    margin-top: 5vh;
    max-width: 70vw;

    @media (--viewport-sm) {
      max-width: 60vw;
    }

    @media (--viewport-lg) {
      max-width: 55vw;
    }

    @media (--viewport-xl) {
      max-width: 50vw;
    }
  }

  & > .outro {
    grid-area: outro;
  }
}

.intro {
  & > .introTitle {
    margin: 0 auto var(--size-40);
    max-width: 18ch;
  }

  & > .introText {
    margin: 0 auto;
    max-width: 30ch;
  }
}

.yearCounterComponent {
  font: var(--font-headingXl);
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: var(--color-red);
  display: grid;
  grid-template-columns: repeat(4, 0.9ch);
  justify-items: center;
  font-variant-numeric: tabular-nums;
  color: transparent;

  & > .thousandsCounter {
    justify-self: end;/* we see a 1 most of the time; kerning looks of if it's justified center */
  }

  @media (--viewport-md) {
    -webkit-text-stroke-width: 2px;
  }

  @media (--viewport-lg) {
    -webkit-text-stroke-width: 4px;
  }
}

.componentPanAndSteam {
  --z-index-pan-shadow: 0;
  --z-index-fire: 10;
  --z-index-pan-back: 20;
  --z-index-steam: 30;
  --z-index-pan-front: 40;

  display: grid;
  grid-template-areas:
    'steam'
    'pan';
  justify-items: center;

  & > .steamVideo {
    grid-area: steam;
    z-index: var(--z-index-steam);
    margin-bottom: -11%; /* position steam inside pan; value determined visually */
    margin-left: 20%; /* center steam above pan; value determined visually */
    min-height: 0;
    width: 80%;
  }

  & > .panAndFireImageStack {
    grid-area: pan;
    min-height: 0;
  }

  & > .cookingPanShadowImage {
    z-index: var(--z-index-pan-shadow);
  }

  & > .fireImage {
    z-index: var(--z-index-pan-shadow);
  }

  & > .cookingPanImage {
    z-index: var(--z-index-pan-back);
  }
}

@keyframes pan-wobble {
  0%,
  100% {
    rotate: 0.12deg;
  }

  50% {
    rotate: -0.12deg;
  }
}

@keyframes fire-wobble {
  0%,
  100% {
    transform: skew(-0.6deg);
  }

  50% {
    transform: skew(0.6deg);
  }
}

.cookingPanImage {
  will-change: transform;
  transform-origin: center 60%;

  &.isWobbling {
    animation: pan-wobble 0.15s linear infinite;
  }
}

.fireImage {
  will-change: transform;
  transform-origin: center 85%;

  &.isWobbling {
    animation: fire-wobble 0.2s linear infinite;
  }
}

.panAndFireImageStack {
  aspect-ratio: calc(2056 / 1352);
}

.outro {
  & > .outroTitle {
    margin: 0 auto var(--size-40);
    max-width: 18ch;
  }

  & > .outroText {
    margin: 0 auto;
  }
}
