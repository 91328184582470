.component {
  gap: var(--size-20);
  display: grid;
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  &.isSmall {
    grid-template-columns: repeat(2, 1fr);
    padding: var(--size-20);
    background-color: var(--color-beige-200);
    border-radius: var(--size-20);
  }

  & > .isDisabled {
    order: -1;
  }
}

.buttonWrapper {
  display: flex;
  gap: var(--size-18);
  justify-content: center;
}

.componentCookiePermission {
  --toggle-button-height: var(--size-40);
  --toggle-button-width: 88px;

  background-color: var(--color-purple-700);
  color: var(--color-white);
  padding: var(--size-30);
  border-radius: var(--border-radius-20);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-18);

  &.isSmall {
    padding: 0;
    gap: var(--size-10);
    background-color: transparent;
  }

  &.isDisabled:not(.isSmall) {
    background-color: var(--color-purple-100);
    color: var(--color-purple-700);
  }

  & > .toggleButton {
    height: var(--toggle-button-height);
    width: var(--toggle-button-width);
    margin-top: auto;
  }
}

.heading {
  font-weight: var(--font-weight-base);
  font-size: var(--font-size-16-18);

  &.isDisabled:not(.isSmall) {
    font-size: var(--font-size-18-26);
    color: var(--color-gray-400);
    color: var(--color-purple-700);
  }

  &.isSmall {
    color: var(--color-purple-700);
  }
}

.content {
  font-size: var(--font-size-12-14);
  line-height: 1.6;
  font-weight: var(--font-weight-base);

  &.isSmall {
    color: var(--color-purple-700);
    font-size: var(--font-size-12-14);
  }
}

.toggleButton {
  --slider-size: var(--size-32);
  --slider-margin: var(--size-4);

  background-color: var(--color-beige-200);
  color: var(--color-purple-700);
  text-transform: uppercase;
  font-size: var(--font-size-10);
  padding: var(--size-4);
  border-radius: var(--border-radius-35);
  position: relative;

  &.isDisabled:not(.isSmall) {
    color: gray;
    cursor: no-drop;
  }

  &.isSmall {
    background-color: var(--color-purple-700);
    color: var(--color-white);
    padding: var(--size-10);
    border-radius: var(--border-radius-20);

    &.isDisabled {
      background-color: var(--color-gray-200);
    }
  }

  & > .slider {
    width: var(--slider-size);
    height: var(--slider-size);
    position: absolute;
    top: var(--slider-margin);
    left: var(--slider-margin);

    &.isActive {
      left: calc(100% - var(--slider-margin) - var(--slider-size));
    }
  }
}

.option {
  opacity: 0;
  transition: opacity var(--duration-150);
  letter-spacing: 0.1em;

  &.isActive {
    opacity: 1;
  }
}

.slider {
  border-radius: 50%;
  transition: var(--duration-300);
  background-color: var(--color-pink-300);

  &.isActive {
    background-color: var(--color-red-300);
  }

  &.isDisabled {
    background-color: var(--color-gray-400);
    cursor: no-drop;
  }
}
