:root {
  --size-4: 4px;
  --size-10: 10px;
  --size-18: 18px;
  --size-20: 20px;
  --size-25: 25px;
  --size-30: 30px;
  --size-32: 32px;
  --size-35: 35px;
  --size-40: 40px;
  --size-45: 45px;
  --size-50: 50px;
  --size-75: 75px;
  --size-100: 100px;
  --size-150: 150px;
  --size-200: 200px;
  --size-400: 400px;

  --curve-height: 300px;

  --container-padding: min(5vw, calc(2.5vw + var(--size-25)));
}
