.page {
  background-color: var(--color-beige);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  & > .sectionLayout {
    position: relative;
    width: 100%;
  }

  & > .sectionCurveInsideLayout {
    position: relative;
    margin-bottom: calc(-1 * var(--curve-height));
    width: 100%;
  }

  & > .progressBarLayout {
    z-index: 10;
  }

  & > .headerLayout {
    position: fixed;
    top: var(--size-25);
    right: var(--size-25);
    z-index: 9;
  }

  & > .sectionHeroLayout { z-index: 8; }
  & > .sectionIntroductionLayout { z-index: 7; }
  & > .sectionTimelineLayout { z-index: 6; }
  & > .sectionProfitLayout { z-index: 5; }
  & > .sectionPayoffLayout { z-index: 3; }
  & > .sectionImpactLayout { z-index: 4; }
  & > .sectionStoryLayout { z-index: 2; }
  & > .sectionLogoPayoffLayout { z-index: 1; }
}
