.componentTable {
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  text-align: left;
  font-size: var(--font-size-12-14);

  & > * {
    width: 100%;
  }
}

.table {
  border-collapse: separate;
  border-spacing: var(--size-10) var(--size-18);
}

.colgroup {
  & > * {
    min-width: 100px;
  }
}

.row {
  & > .cellLayout:first-child {
    min-width: 25ch;
  }
}
