.component {
  & > .layout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }
}

.layout {
  & > .inner {
    height: 100%;
    width: 100%;
  }
}

.inner {
  background: var(--color-red);
  scale: 0 1;
  transform-origin: top left;
}
