.page {
  background-color: var(--color-beige-200);
  display: grid;
  position: relative;
  z-index: 0;
  justify-items: center;

  & > .content,
  & > .image {
    grid-area: 1 / -1;
  }

  & > .image {
    z-index: -1;
    height: 100%;
    max-height: 100dvh;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-inline: var(--size-24);

  & > .heading {
    margin-bottom: var(--size-35);
  }
}

.image {
  object-fit: cover;
  object-position: center bottom;
}
