.component {
  padding-inline: var(--container-padding);
  padding-block-start: var(--size-100);
  padding-block-end: var(--size-50);

  @media (--viewport-md) {
    padding-block-start: var(--size-150);
    padding-block-end: var(--size-200);
  }

  & > .layout {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.layout {
  display: grid;
  grid-template: repeat(10, 80px) / repeat(20, 1fr);
  position: relative;
  z-index: 0;
  align-items: start;

  @media (--viewport-md) {
    grid-template: repeat(10, 100px) / repeat(20, 1fr);
  }

  & > * {
    min-width: 0;
    width: 100%;
  }

  & > .header {
    grid-area: 4 / 1 / 8 / 21;
    z-index: 2;

    @media (--viewport-lg) {
      grid-area: 5 / 1 / 8 / 21;
    }
  }

  & > .imageContainer1 {
    grid-area: 2 / 1 /  -1 / -1;
    width: min(30vw, 230px);

    @media (--viewport-lg) {
      grid-area: 3 / 1 /  -1 / -1;
    }
  }

  & > .imageContainer2 {
    width: min(20vw, 180px);
    grid-area: 1 / 12 / -1 / -1;

    @media (--viewport-lg) {
      grid-area: 1 / 10 / -1 / -1;
    }
  }

  & > .imageContainer3 {
    width: min(13vw, 115px);
    grid-area: 3 / 16 / -1 / -1;

    @media (--viewport-md) {
      grid-area: 3 / 17 / -1 / -1;
    }
  }

  & > .imageContainer4 {
    width: min(18vw, 185px);
    grid-area: 9 / 2 / -1 / -1;

    @media (--viewport-lg) {
      grid-area: 9 / 2 / -1 / -1;
    }
  }

  & > .imageContainer5 {
    width: min(30vw, 125px);
    grid-area: 9 / 10 / -1 / -1;

    @media (--viewport-lg) {
      grid-area: 9 / 9 / -1 / -1;
    }
  }

  & > .imageContainer6 {
    @media (--viewport-md) {
      width: min(10vw, 235px);
      grid-area: 8 / 18 / -1 / -1;
    }

    @media (--viewport-lg) {
      grid-area: 6 / 18 / -1 / -1;
    }
  }

  & > .imageContainer7 {
    @media (--viewport-lg) {
      width: min(15vw, 205px);
      grid-area: 10 / 13 / -1 / -1;
    }
  }

  & > .peperContainer {
    @media (--viewport-lg) {
      max-width: 400px;
      height: 100%;
      grid-area: 9 / 17 / -1 / -1;
      width: min(15vw, 350px);
    }
  }

  & > .babyMaisContainer {
    @media (--viewport-lg) {
      max-width: 400px;
      height: 100%;
      grid-area: 2 / 2 / -1 / -1;
      width: min(15vw, 350px);
    }
  }
}

.header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--size-10);
  padding-top: var(--size-20);
  visibility: hidden;
  opacity: 0;

  @media (--viewport-lg) {
    gap: var(--size-25);
    padding-top: 0;
  }

  & > .subheading {
    margin-top: 8px;
  }
}

.heading {
  font: var(--font-headingLg);
  text-wrap: balance;
  text-align: center;
  padding: 0 3vw;
}

.subheading {
  padding: 0 32px;
}

.imageContainer {
  border-radius: var(--border-radius-10);
  box-shadow: 6px 6px var(--color-red);
  overflow: hidden;

  @media (--viewport-md) {
    box-shadow: 7px 7px var(--color-red);
  }

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.imageContainer6 {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.imageContainer7,
.peperContainer,
.babyMaisContainer {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.image {
  object-fit: cover;
}
