.component {
  --image-height: 1277;
  --point-y: 350;

  height: calc(var(--height-in-vh, 500) * 1vh); /* var is set in js, so we have everything gsap-related in the same place */
  overflow: hidden;
}

.layout {
  display: grid;
  position: relative;
  z-index: 0;

  & > * {
    min-width: 0;
    grid-area: 1 / -1;
    height: 100vh;
  }

  & > .etVoilaContainer {
    z-index: 1;
  }

  & > .imageContainer {
    z-index: 2;
  }

  & > .headingContainer {
    z-index: 3;
  }
}

.etVoilaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: var(--size-25);

  @media (--viewport-lg) {
    padding-inline: 0;
  }

  & > .etVoilaText {
    width: 100%;
    max-width: 1250px;
  }
}

.etVoilaText {
  visibility: hidden;
  opacity: 0;
}

.headingContainer {
  padding-inline: var(--container-padding);
  display: grid;
  align-items: center;

  & > * {
    min-width: 0;
    grid-area: 1 / -1;
  }
}

.heading {
  color: var(--color-beige);
  text-align: center;
  visibility: hidden;
  opacity: 0;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.imageContainer {
  clip-path: circle(20% at 50% 50%);

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: cover;
  object-position: 50% calc(var(--point-y) / var(--image-height) * 100%);
  scale: 2;
  transform-origin: 50% 0;
}
