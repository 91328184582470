.component {
  overflow: hidden;
  padding-top: var(--size-400);

  & > .layout {
    min-height: 50vh;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > .heading {
    max-width: 35ch;
  }
}

.button {
  display: inline-block;
  color: var(--color-red);
  position: relative;
  z-index: 0;
  font: inherit;
  cursor: pointer;

  & > .heartsLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  & > .underline {
    position: absolute;
    inset: 0;
  }

  &:hover,
  &:focus {
    & .line {
      transform: translate3d(-66.6%, 0, 0);
    }
  }
}

.underline {
  position: relative;
  pointer-events: none;
  display: block;
  overflow-x: clip;

  & > .line {
    position: absolute;
    top: 4px;
    left: 0;
    max-width: none;
  }
}

.line {
  pointer-events: none;
  fill: none;
  stroke: currentColor;
  stroke-width: 5px;
  transition: transform 0.7s;
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.inner {
  display: inline-block;
  animation: number-pulse 1.3s linear infinite;
  user-select: none;
}

@keyframes number-pulse {
  0% { transform: scale(1); }
  5% { transform: scale(1.2); }
  10% { transform: scale(1); }
  15% { transform: scale(1.1); }
  20% { transform: scale(1); }
  100% { transform: scale(1); }
}

.componentHearts {
  display: grid;
  justify-items: center;

  & > .heartShape {
    --size: 75px;

    grid-area: 1 / -1;
    width: var(--size);
    height: var(--size);

    @media (--viewport-md) {
      --size: 75px;
    }
  }
}

.heartShape {
  user-select: none;
  pointer-events: none;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: contain;
}

.heading {
  padding-inline: var(--container-padding);
}

.noUserSelect {
  user-select: none;
}
