.component {
  --curve-height: 200px;

  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  background-color: var(--bg-color, var(--color-beige));

  & > .bottomCurveLayout {
    position: absolute;
    bottom: calc(-1 * var(--curve-height) + 1px);/* + 1px to prevent gap on small screens */
    height: var(--curve-height);
    width: 100%;
  }
}

.componentCover {
  position: relative;

  & > .bottomCurveCoverLayout {
    position: absolute;
    bottom: -90px; /* with bottom: 0, you see part of underlying image when scrolling quickly. -90 is determined visually */
    left: 0;
    right: 0;
    height: 300px; /* same as --curve-height in Section.css */
  }
}

.default {
  --bg-color: var(--color-beige);
}

.purple {
  --bg-color: var(--color-purple);
}

.red {
  --bg-color: var(--color-red);
}

.beige {
  --bg-color: var(--color-beige);
}
