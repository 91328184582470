.component {
  --bg-color: var(--color-beige);
  --color-text: var(--color-purple);
  --bg-color-icon: var(--color-red);
  --color-icon: var(--color-beige);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--size-200) var(--container-padding) 0;

  @media (--viewport-md) {
    padding: var(--size-200) var(--container-padding) var(--size-50);
  }

  & > .heading {
    margin-bottom: var(--size-50);
    max-width: 19ch;
  }

  & > .buttons {
    margin-bottom: var(--size-100);
    width: 100%;
  }

  & > .cardsContainer {
    @media (--viewport-md) {
      max-width: 1400px;
    }
  }
}

.heading {
  color: var(--color-beige);
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--size-35);
  opacity: 0;
  visibility: hidden;

  & > * {
    width: min(100%, 300px);
  }
}

.cardsContainer {
  & > .linkCardLayout {
    max-width: 90vw;
    margin-bottom: var(--size-40);
  }

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: var(--size-20);
  }

  @media (--viewport-lg) {
    gap: var(--size-40);
  }
}

.componentLinkCard {
  --border-radius: var(--border-radius-20);
  --padding: var(--size-20);

  display: block;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  padding: var(--padding);
  color: var(--color-text);
  transition: var(--duration-600) var(--ease-out-back);
  box-shadow: 0 0 var(--size-10) rgba(0 0 0 / 50%);
  transition-property: background-color, color;
  position: relative;
  visibility: hidden;

  @media (--viewport-sm) {
    transition-property: background-color, color, scale, rotate;
  }

  @media (--viewport-md-only) {
    --padding: var(--size-10);
  }

  @media (--viewport-lg) {
    --border-radius: var(--border-radius-40);
  }

  &:hover,
  &:focus-visible {
    @media (--viewport-md) {
      --bg-color: var(--color-red);
      --color-text: var(--color-beige);
      --bg-color-icon: var(--color-purple);

      scale: 1.05;
      rotate: -1.5deg;
    }

    &:nth-child(2) {
      @media (--viewport-md) {
        rotate: 1.5deg;
      }
    }
  }

  & > .cardCta {
    margin: var(--size-20) var(--size-10) 0 var(--size-10);
  }
}

.cardImage {
  border-radius: calc(var(--border-radius) - var(--padding));
}

.cardCta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-10);
  padding-bottom: var(--size-4);

  &.relativeToParent {
    position: static;
  }

  & > .link {
    flex-shrink: 0;
    width: var(--size-50);

    @media (--viewport-md) {
      width: var(--size-35);
    }

    @media (--viewport-lg) {
      width: var(--size-50);
    }
  }
}

.link {
  position: relative;

  &.relativeToParent {
    position: static;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.ctaText {
  font-size: var(--font-size-16-18);
  text-align: left;
}

.componentCtaIcon {
  display: grid;
  place-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--bg-color-icon);
  color: var(--color-icon);
  transition: background-color var(--duration-150) linear;

  & > .iconLayout {
    width: var(--size-25);

    @media (--viewport-md-only) {
      width: var(--size-20);
    }
  }
}
