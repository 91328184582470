.component {
  padding-top: var(--size-100);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1286px) minmax(0, 1fr);
  grid-template-rows: 50svh auto;
  z-index: 0;
  position: relative;
  overflow-x: clip;
  background: url('/images/hero/hero-bg.jpg') no-repeat bottom center;
  background-size: cover;

  @media (--viewport-md) {
    padding-top: 0;
    grid-template-rows: 75svh 25svh;
  }

  & > * {
    min-width: 0;
  }

  & > .panContainer {
    grid-row: 1;
    grid-column: 2;
  }

  & > .groenteContainer {
    margin-top: calc(-1 * var(--size-100));
    z-index: 1;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-self: center;

    @media (--viewport-md) {
      margin-top: 0;
    }
  }

  & > .datSmaaktNaarBeterContainer {
    align-self: start;
    justify-self: center;
    grid-column: 2;
    grid-row: 2;
  }
}

.datSmaaktNaarBeterContainer {
  padding-inline: var(--container-padding);

  @media (--viewport-lg) {
    padding-inline: 0;
  }

  & > .datSmaaktNaarBeter {
    width: 75vmin;
    max-width: 700px;
    height: 100%;

    @media (--viewport-xl) {
      max-width: 1040px;
    }
  }
}

.path {
  fill: none;
  stroke: none;
}

.curveText {
  visibility: hidden;
  opacity: 0;
  color: var(--color-red);
  font-size: 46px;
}

.panContainer {
  display: grid;
  position: relative;
  justify-items: center;
  z-index: 0;

  & > * {
    min-width: 0;
    grid-area: 1 / -1;
  }

  & > .groeneCurryText {
    z-index: 1;
    width: clamp(340px, 75vw, 940px);
    align-self: center;

    @media (--viewport-xl) {
      width: clamp(340px, 45vw, 940px);
    }
  }

  & > .pan {
    z-index: -1;
  }

  & > .pan,
  & > .deksel {
    height: 50svh;
    width: 100%;

    @media (--viewport-md) {
      height: 75svh;
    }
  }
}

.pan {
  /* filter: drop-shadow(-15px 11px 10px rgba(54, 16, 2, 0.2)) drop-shadow(-30px 22px 40px rgba(54, 16, 2, 0.25)) drop-shadow(-60px 44px 80px rgba(54, 16, 2, 0.3)); */
  filter: drop-shadow(-60px 44px 80px rgba(54, 16, 2, 0.3));
}

.pan,
.deksel {
  object-fit: contain;
}

.groeneCurryText {
  padding-inline: var(--size-75);
  visibility: hidden;
  opacity: 0;

  & > svg {
    height: auto;
  }
}

.groenteContainer {
  visibility: hidden;
  opacity: 0;
  display: grid;
  grid-template: repeat(10, 1fr) / repeat(20, 1fr);

  & > * {
    min-width: 0;
    width: 100%;
  }

  & > .paprika1Container {
    width: min(25vmin, 413px);
    grid-area: 1 / 1 / -1 / -1;

    @media (--viewport-xl) {
      grid-column: 2;
    }
  }

  & > .paprika2Container {
    width: min(30vmin, 250px);
    grid-area: 1 / 17 / -1 / -1;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > .bimiContainer {
    width: min(30vmin, 421px);
    grid-area: 4 / 16 / -1 / -1;

    @media (--viewport-md) {
      grid-row: 4;
    }
  }

  & > .korianderContainer {
    width: min(10vmin, 126px);
    grid-area: 10 / 12 / -1 / -1;
  }

  & > .peper1Container {
    width: min(10vmin, 119px);
    grid-area: 5 / 3 / -1 / -1;
  }

  & > .peper2Container {
    width: min(5vmin, 36px);
    grid-area: 8 / 17 / -1 / -1;
    display: none;

    @media (--viewport-lg) {
      display: block;
      grid-column: 18;
    }
  }

  & > .peper3Container {
    width: min(10vmin, 106px);
    grid-area: 7 / 20 / -1 / -1;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > .sperziebonenContainer {
    width: min(22vmin, 216px);
    grid-area: 7 / 3 / -1 / -1;

    @media (--viewport-md) {
      grid-column: 5;
    }
  }

  & > .limoenContainer {
    width: min(18vmin, 232px);
    grid-area: 7 / 18 / -1 / -1;

    @media (--viewport-md) {
      grid-row: 8;
    }
  }
}
