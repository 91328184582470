.component {
  & > .cookieTogglesWrapper {
    margin-bottom: var(--size-40);
  }
}

.buttonWrapper {
  display: flex;
  gap: var(--size-18);
  justify-content: center;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row-reverse;
  }
}

.componentCookiePermission {
  &.isDisabled {
    background-color: var(--color-gray-200);
  }
}
