.componentBase {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--bg-color);

  &::after {
    content: '';
    position: absolute;
    background-color: var(--bg-color);
    clip-path: ellipse(100% 100% at 50% -35%);
    bottom: calc(-1 * var(--curve-height)); /* + 1px to prevent gap on small screens */
    height: var(--curve-height);
    width: 100%;

    @media (--viewport-lg) {
      clip-path: ellipse(65% 100% at 50% -35%);
    }
  }
}

.componentCurveInside {
  background: linear-gradient(to bottom, var(--bg-color) 0, var(--bg-color) calc(100% - var(--curve-height)), transparent 0);

  &::after {
    z-index: -1;
    bottom: 1px; /* 1px to prevent gap on small screens */
  }
}

.default {
  --bg-color: var(--color-beige);
}

.red {
  --bg-color: var(--color-red);
}

.beige {
  --bg-color: var(--color-beige);
}

.purple {
  --bg-color: var(--color-purple);
}
