:root {
  --color-white: #fff;

  --color-gray-200: #ccc;
  --color-gray-400: #666;

  --color-purple-700: #4e012d;
  --color-purple-100: #4e012d30;

  --color-beige-200: #fff8ef;

  --color-red-300: #eb3754;

  --color-pink-300: #ffd9d0;

  /* General Purpose Variables with Fallbacks */
  --color-purple: var(--color-purple-700);
  --color-red: var(--color-red-300);
  --color-beige: var(--color-beige-200);
  --color-pink: var(--color-pink-300);
}
