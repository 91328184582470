.component {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--bg-color);
}

.default {
  --bg-color: var(--color-beige);
}

.red {
  --bg-color: var(--color-red);
}

.beige {
  --bg-color: var(--color-beige);
}

.purple {
  --bg-color: var(--color-purple);
}
