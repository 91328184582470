.component {
  padding-top: var(--size-200);
  padding-bottom: 0;
  padding-inline: var(--container-padding);
  overflow: hidden;

  @media (--viewport-lg) {
    padding-inline: 0;
    padding-bottom: var(--size-100);
  }

  & > .layout {
    margin-inline: auto;
    min-height: 100vh;
    width: min(100%, 1234px);
  }
}

.layout {
  display: grid;
  grid-template-rows: auto 75vh auto;
  gap: var(--size-50);

  & > * {
    min-width: 0;
  }

  & > .header {
    margin-inline: auto;
    align-self: end;
  }

  & > .descriptions {
    align-self: center;

    @media (--viewport-md) {
      margin-top: var(--size-50);
    }
  }
}

.header {
  & > .heading {
    max-width: 18ch;
  }
}

.heading {
  font: var(--font-headingLg);
  text-wrap: balance;
  text-align: center;
}

.descriptions {
  display: flex;
  flex-direction: column;
  gap: var(--size-25);

  @media (--viewport-md) {
    gap: var(--size-75);
    flex-direction: row;
  }

  & > .description {
    width: 100%;
  }
}

.description {
  text-wrap: balance;
  text-align: center;
  font-size: var(--font-size-20-35);

  &:first-child {
    @media (--viewport-md) {
      text-align: right;
    }
  }

  &:last-child {
    @media (--viewport-md) {
      text-align: left;
    }
  }
}

.cuttingBoardContainer {
  display: grid;
  user-select: none;
  position: relative;
  z-index: 0;

  & > * {
    min-width: 0;
  }

  & > .cuttingBoardImage {
    margin-inline: auto;
    height: 75vh;
    z-index: -1;
    grid-area: 1 / -1;
  }

  & > .profitContainer {
    margin-right: -4vw;
    place-self: center;
    width: min(50vmin, 910px);
    grid-area: 1 / -1;

    @media (--viewport-lg) {
      width: min(80vmin, 950px);
    }
  }

  & > .clothContainer {
    @media (--viewport-lg) {
      position: absolute;
      width: min(100%, 1040px);
      left: calc(100% - 250px);
      top: -200px;
    }
  }
}

.cuttingBoardImage {
  object-fit: contain;
  aspect-ratio: 355 / 571;
  filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.3));

  @media (--viewport-lg) {
    aspect-ratio: 1234 / 766;
  }

  &.isMobile {
    @media (--viewport-lg) {
      display: none;
    }
  }

  &.isDesktop {
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }
}

.profitContainer {
  aspect-ratio: 910 / 294;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  & > * {
    grid-row: 1;
    min-width: 0;
    width: 100%;
    height: 100%;
  }

  & > .profit1 {
    grid-column: 1;
  }

  & > .pieces {
    grid-column: 1 / -1;
    margin-top: -75px;
    margin-right: -10px;
    width: 25px;
    justify-self: center;

    @media (--viewport-md) {
      margin-top: -50px;
      width: 40px;
    }
  }

  & > .profit2 {
    grid-column: 2;
  }
}

.pieces {
  opacity: 0;
  visibility: hidden;
}

.clothContainer {
  display: none;
  pointer-events: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.cloth {
  filter: drop-shadow(0 15px 15px rgba(0, 0, 0, 0.2));
}
