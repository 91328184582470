.page {
  padding-block: var(--size-200);
  background-color: var(--color-beige);
}

.layout {
  display: flex;
  flex-direction: column;

  & > .header {
    margin-bottom: var(--size-50);
  }

  & > .link {
    margin-top: var(--size-10);
  }

  & > .cookieTable {
    margin-block: var(--size-50);
  }

  & > .buttonLayout {
    margin-inline: auto;
  }
}

.header {
  text-align: center;
}

.content {
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-base);
}

.link {
  color: var(--color-purple);
  font-size: var(--font-size-16-18);
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
