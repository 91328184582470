:root {
  --font-family-base: 'Neulis Alt', sans-serif;
  --font-weight-base: 400;

  --font-weight-500: 500;
  --font-weight-700: 700;

  --font-line-height-base: 1.2;
  --font-line-height-1: 1;

  /* Sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--m)), 14px);
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-16-22: clamp(16px, calc(16px + 6 * var(--m)), 22px);
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-18-26: clamp(18px, calc(18px + 8 * var(--m)), 26px);
  --font-size-20-35: clamp(20px, calc(20px + 15 * var(--m)), 35px);
  --font-size-24-28: clamp(24px, calc(24px + 4 * var(--m)), 28px);
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--m)), 40px);
  --font-size-40-88: clamp(40px, calc(40px + 48 * var(--m)), 88px);
  --font-size-150-500: clamp(150px, calc(150px + 365 * var(--m)), 500px);

  --font-size-10: 10px;
  --font-size-20: 20px;
  --font-size-26: 26px;
  --font-size-35: 35px;
  --font-size-88: 88px;
  --font-size-500: 500px;

  /* tie vars to usage */
  --font-family-heading: var(--font-family-base);
  --font-weight-heading: var(--font-weight-700);

  --font-headingXl:
    var(--font-weight-heading)
    var(--font-size-150-500) / var(--font-line-height-1)
    var(--font-family-heading);
  --font-headingLg:
    var(--font-weight-700)
    var(--font-size-40-88) / var(--font-line-height-1)
    var(--font-family-heading);
  --font-headingMd:
    var(--font-weight-heading)
    var(--font-family-heading)
    var(--font-size-20-35);
}
