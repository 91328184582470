.component {
  padding: var(--size-200) 0 var(--size-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-75);

  & > .logoLayout {
    width: min(60vw, 430px);
  }
}

.link {
  color: var(--color-purple);
  font-size: var(--font-size-12-14);
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
