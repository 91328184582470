.componentBase,
.componentLinkBase {
  display: flex;
  justify-content: center;
  gap: var(--size-10);
  padding: min(4vw, var(--size-20)) min(6vw, var(--size-50));
  border-radius: var(--border-radius-50);
  white-space: nowrap;
  font-family: var(--font-family-base);
  font-size: var(--font-size-16-22);
  line-height: 1;
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .psuedo {
    position: absolute;
    inset: 0;
  }

  &:hover,
  &:focus {
    & > .psuedo {
      clip-path: ellipse(65% 99% at 50% 50%);
    }
  }
}

.children,
.psuedo {
  display: flex;
  gap: var(--size-10);
  justify-content: center;
  align-items: center;
}

.psuedo {
  background-color: var(--color-pink);
  color: var(--color-purple);
  transition: clip-path ease-out 0.4s;
  clip-path: ellipse(65% 99% at 50% -100%);
}

.componentLinkPrimary,
.componentPrimary {
  background-color: var(--color-purple);
  color: var(--color-beige);
}

.componentLinkSecondary,
.componentSecondary {
  background-color: var(--color-red);
  color: var(--color-beige);
}

.componentLinkTertiary,
.componentTertiary {
  background-color: var(--color-beige);
  color: var(--color-purple);
}
