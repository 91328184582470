.component {
  & > .svgPath {
    width: 100%;
    height: 100%;
  }
}

.svgPath {
  fill: var(--bg-color);
  opacity: 1;
}

.curve {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.curveLg {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
