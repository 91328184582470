.component {
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .layout {
    height: 120vh;
  }
}

.debugButton {
  margin-inline: 1em;
  position: relative;
  padding: 1em;
  border: 1px solid var(--color-beige);
}

.layout {
  position: relative;
  z-index: 0;
  display: grid;
  place-items: center;
  grid-template-rows: auto;
  grid-template-columns: auto;
  text-align: center;

  & > .heading {
    align-self: start;
    position: relative;
    z-index: 1;
    grid-area: 1 / 1 / 2 / 2;
    margin-top: 30vh;
    max-width: 28ch;
  }

  & > .worldWrapper {
    grid-area: 1 / 1 / 2 / 2;
    align-self: start;
    margin-top: -16px;
    width: 100%;
    height: 100%;
  }
}

.heading {
  padding-inline: var(--container-padding);
  color: var(--color-beige);
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  @media (--viewport-sm) {
    text-shadow: none;
  }
}

.worldWrapper {
  --pointer: coarse;

  @media (pointer: fine) {
    --pointer: fine;
  }
}

/* svg container for dev purposes - to see the curve */
/* to be removed later */
.svgContainer {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px; /* same as --curve-height in Section.css */

  & > .svgPath {
    display: block;
    width: 100%;
    height: 100%;
    fill: var(--color-beige);
    opacity: 0.5;
  }
}

.curve {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.curveLg {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
